import React from "react";
import '../style/contact.css';
import { useLayoutEffect } from "react";

const Contact = () => {
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div>
            <section className="contact-form">
                <div className="contact-form-header">
                    <h1 className="landing-h1 background-clip-text">Kontakt</h1>
                    <h2>Kontaktirajte nas</h2>
                    <p>
                        MKM Global Building - građevinska firma u Beogradu.
                        Za sve vrste građevinskih radova, uređenje enterijera i adaptaciju prostora,
                        kontaktirajte nas putem forme ispod ili direktno na naše kontakte.
                    </p>
                    <p className="fill">Popunite kontakt formu ispod ili nam se javite</p>
                </div>

                <form>

                    <div className="form-group">
                        <div className="input-row">
                            <input className="row-item" type="text" placeholder="Ime i Prezime" />
                            <input className="row-item" type="text" placeholder="Email" />
                        </div>
                        <div className="input-row">
                            <input className="row-item" type="text" placeholder="Broj telefona" />
                            <input className="row-item" type="text" placeholder="Zvanje" />
                        </div>
                        <div className="input-row">
                            <input className="row-item" type="text" placeholder="Kompanija" />
                            <input className="row-item" type="text" placeholder="Tema" />
                        </div>
                        <textarea className="message" placeholder="Poruka"></textarea>

                    </div>
                    <div className="button-container">
                        <p>* Slanjem poruke prihvatate uslove korišćenja i politiku privatnosti</p>
                        <button>Posalji</button>

                    </div>
                </form>
            </section>
            <section className="contact-info">
                <div className="contact-info-left">
                    <h1>MKM GLOBAL BUILDING DOO</h1>
                    <div className="data">
                        <h2>Adresa</h2>
                        <p>Beograd, Srbija</p>
                        <h2>Telefon</h2>
                        <p>+381 11 123 456</p>
                        <h2>Email</h2>
                        <p>info@mkmglobal.rs</p>
                        <h2>PIB</h2>
                        <p>123456789</p>
                        <h2>Matični broj</h2>
                        <p>123456789</p>
                    </div>
                </div>
                <div className="contact-info-right">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.9935874859874!2d20.419843776491547!3d44.80519977909878!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a6fed108da8b1%3A0x9c662a8e931516c9!2sBulevar%20oslobo%C4%91enja%2094%2C%20Beograd!5e0!3m2!1sen!2srs!4v1709913445793!5m2!1sen!2srs"
                        width="100%" 
                        height="100%" 
                        style={{border:0}} 
                        allowFullScreen="" 
                        loading="lazy" 
                        referrerPolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </section>
        </div>
    );
}

export default Contact;
