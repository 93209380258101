// create a new file called Footer.js in the components folder and add the following code:

import React from 'react';
import '../style/footer.css';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <>
            {/* Schema.org strukturirani podaci */}
            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "name": "MKM Global Building",
                    "url": "https://www.mkmglobal.rs",
                    "logo": "https://www.mkmglobal.rs/logo.png",
                    "description": "Profesionalna građevinska firma u Beogradu. Izgradnja, enterijer, rekonstrukcija.",
                    "address": {
                        "@type": "PostalAddress",
                        "streetAddress": "Bulevar Oslobođenja 94/31",
                        "addressLocality": "Beograd",
                        "addressCountry": "RS"
                    },
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "telephone": "011-123-456",
                        "contactType": "customer service",
                        "email": "info@mkmglobal.rs"
                    },
                    "sameAs": [
                        "https://www.facebook.com/mkmglobal",
                        "https://www.linkedin.com/company/mkm-global"
                    ]
                })}
            </script>

            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="footer-col">
                            <h2>Informacije</h2>
                            <ul>
                                <li>PIB: 108977989 </li>
                                <li>MB: 21092142 </li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h2>Kontakt</h2>
                            <ul>
                                <li>info@mkmglobal.rs</li>
                                <li>011/ 123-456</li>
                            </ul>
                        </div>
                        <div className="footer-col">
                            <h2>Adresa</h2>
                            <ul>
                                <li>MKM Global Building d.o.o Beograd</li>
                                <li>Bulevar Oslobođenja 94/31, Beograd</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="rights">© Copyright 2025 MKM GLOBAL DOO</div>
            </footer>
        </>
    );
}

export default Footer;